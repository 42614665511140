import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import Page from '../components/Page';
import variants from '../utils/variants';
import theme from '../utils/theme';
import { RouterLink } from '../utils/styledComponents';
import { garagesRoute } from '../utils/redirects';
import GarageStats from '../components/Garageinfo';
import {
  convertHashMapToArray,
  errorLogger,
} from '../utils/helpers/component';
import apiInstance from '../utils/apiInstance';

const Container = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.miscellaneous.white};
  padding: 20px 20px 10px 20px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 10px 20px;
`;

const LogoBox = styled.div`
  width: 168px;
  height: 168px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  border: 1px solid #fef3f1;
  box-shadow: 0px 4px 8px rgba(228, 228, 229, 0.05), 0px 8px 8px rgba(198, 198, 199, 0.15);
`;

const GarageInformation = styled.div`
  margin-left: 20px;
`;

const Topline = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
`;

const Name = styled.p`
  color: ${(props) => props.theme.colors.primary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 600;
`;

const Span = styled.span`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
`;

const Address = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  margin: 15px 0px;
`;

const Contactdetails = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;
  flex-wrap: wrap;
`;

const ContactText = styled.p`
  color: ${(props) => props.theme.colors.secondary.light};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  margin-right: 20px;
  Span {
    font-size: ${(props) => props.theme.fontSizes['text-lg']};
  }
`;

const CustomButton = styled.button`
  padding: 12px;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-weight: 700;
  border: 1px solid ${(props) => props.theme.colors.secondary.light};
  background-color: ${(props) => props.theme.colors.miscellaneous.white};
  width: 180px;
  cursor: pointer;
`;

const ManagerDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: auto;
  border-left: 2px solid ${(props) => props.theme.colors.secondary.lighter};
  padding-left: 50px;
`;

const Designation = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-base']};
`;

const Garage = () => {
  const params = useParams();
  const { addToast } = useToasts();
  const garages = useSelector((state) => state?.garages);
  const navigate = useNavigate();
  const { garageId } = params;
  const allGarages = convertHashMapToArray(garages?.value);
  const garage = allGarages.filter((garage) => garage.id === parseInt(garageId, 10))[0];
  const [garageBookings, setGarageBookings] = useState([]);
  const [garageStats, setGarageStats] = useState([]);
  const [salesGraphData, setSalesGraphData] = useState({
    daily: [],
    weekly: [],
    monthly: [],
  });

  // useEffect(() => {
  //   const getSalesGraphData = async () => {
  //     const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     const date = getCurrentMomentDateInputObject();
  //     const fromDate = new Date(`${date?.year}-${date?.month}-${date?.day}`);
  //     try {
  //       const daily = apiInstance.get(
  //         `bookings/admin/getSalesStats?query=daily&fromDate=${fromDate.toISOString()}&garage_id=${garageId}&tz=${tz}`
  //       );
  //       const weekly = apiInstance.get(
  //         `bookings/admin/getSalesStats?query=weekly&fromDate=${fromDate.toISOString()}&garage_id=${garageId}&tz=${tz}`
  //       );
  //       const monthly = apiInstance.get(
  //         `bookings/admin/getSalesStats?query=monthly&fromDate=${fromDate.toISOString()}&garage_id=${garageId}&tz=${tz}`
  //       );
  //       const res = await Promise.all([daily, weekly, monthly]);
  //       setSalesGraphData((state) => ({
  //         ...state,
  //         ...formatSalesGraphData({
  //           daily: res[0].data.content,
  //           weekly: res[1].data.content,
  //           monthly: res[2].data.content,
  //           fromDate,
  //         }),
  //       }));
  //     } catch (error) {
  //       errorLogger(error, () => {});
  //     }
  //   };
  //   getSalesGraphData();
  // }, []);

  // useEffect(() => {
  //   const getGarageBookings = async () => {
  //     try {
  //       const res = await apiInstance.get(`bookings/getBookingsByGarage/${garageId}`);
  //       const bookings = res.data.content;
  //       setGarageBookings(bookings);
  //     } catch (error) {
  //       errorLogger(error, addToast, () => {});
  //     }
  //   };
  //   getGarageBookings();
  // }, []);

  // useEffect(() => {
  //   const getGarageStats = async () => {
  //     try {
  //       const res = await apiInstance.get(`garages/admin/stats/getGarageStats?garage_id=${garageId}`);
  //       const stats = res.data.content;
  //       setGarageStats(stats);
  //     } catch (error) {
  //       errorLogger(error, addToast, () => {});
  //     }
  //   };
  //   getGarageStats();
  // }, []);

  return (
    <Page variant={variants.general} header="Garage Info" title="Garages">
      <Container>
        <RouterLink to={garagesRoute}>
          <i className="fa-sharp fa-solid fa-arrow-left fa-lg" style={{ color: theme.colors.secondary.main }} />
        </RouterLink>
        <Top>
          <LogoBox>
            <Avatar src={garage?.logo} sx={{ width: '75%', height: '75%' }} />
          </LogoBox>
          <GarageInformation>
            <Address>
              {garage?.address}
            </Address>
            <Contactdetails>
              <ContactText>
                Mobile Number: <Span>{garage?.contact_number}</Span>
              </ContactText>
              <ContactText>
                Email: <Span>{garage?.email}</Span>
              </ContactText>
            </Contactdetails>
            <CustomButton
              onClick={() => {
                navigate(`/garage/editGarage/${garage.id}`);
              }}
            >
              EDIT GARAGE
            </CustomButton>
          </GarageInformation>
          { garage?.user?.map((user) => (
            <ManagerDetails>
              <Name>
                {user?.first_name} {user?.last_name}
              </Name>
              <Designation>Manager</Designation>
              <ContactText>
                Email: <Span>{user?.email}</Span>
              </ContactText>
              <ContactText>
                Mobile Number:<Span>{user?.phone_number}</Span>
              </ContactText>
              <CustomButton
                onClick={() => {
                  navigate(`/garage/editManager/${garage.id}/${user?.id}`);
                }}
              >
                EDIT MANAGER
              </CustomButton>
            </ManagerDetails>
          ))


          }
          
        </Top>
        {/* <GarageStats salesGraphData={salesGraphData} garageBookings={garageBookings} garageStats={garageStats} /> */}
      </Container>
    </Page>
  );
};

export default Garage;
