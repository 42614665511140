import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import theme from '../../utils/theme';
import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';
import apiInstance from '../../utils/apiInstance';
import { signIn } from '../../utils/redux/userSlice';
import { errorLogger } from '../../utils/helpers/component';

const OtpForm = styled.form``;

const WelcomeHeading = styled.h2`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-3xl']};
  font-weight: 400;
  font-style: normal;
  line-height: ${(props) => props.theme.lineHeights['text-3xl']};
`;

const LoginText = styled.div`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
  margin-top: 5px;
`;

const TimerText = styled.p`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
  margin-top: 5px;
`;

const OtpInputContainer = styled.div`
  margin: 30px 0 50px 0;
`;

const ResendText = styled.p`
  color: ${(props) => (props.disabled ? props.theme.colors.secondary.lighter : props.theme.colors.primary.main)};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-sm']};
  margin-top: 15px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
`;

const OtpWidget = (props) => {
  const { email, otp, setOtp } = props;
  const [otpResendTimer, setOtpResendTimer] = useState(30);
  const [resendOtpDisabled, setResendOtpDisabled] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const resendTimeHandler = useCallback(() => {
    setOtpResendTimer(30);
    setResendOtpDisabled(true);
    const timerId = setInterval(() => {
      setOtpResendTimer((time) => {
        if (time - 1 === 0) {
          clearInterval(timerId);
          setResendOtpDisabled(false);
          return 0;
        }
        return time - 1;
      });
    }, 1000);
    return timerId;
  }, []);

  useEffect(() => {
    const timerId = resendTimeHandler();
    return () => {
      clearInterval(timerId);
    };
  }, [resendTimeHandler]);

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const res = await apiInstance.post('/auth/admin/otp/verify', { email, otp });
      dispatch(
        signIn({
          userDetails: { ...res?.data?.content },
          accessToken: res?.data?.content?.accessToken,
          refreshToken: res?.data?.content?.otp_token,
        })
      );
      addToast('Sign In Successful.', { appearance: 'success', autoDismiss: true });
      setSubmitLoading(false);
      window.location.href = '/';
    } catch (error) {
      errorLogger(error, addToast, () => {
        setSubmitLoading(false);
      });
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e);
  };

  const handleOtpResend = async () => {
    const timerId = resendTimeHandler();
    try {
      await apiInstance.post('admin//otp/create', { email });
      addToast(`OTP Sent Successfully To ${email}`, { appearance: 'success', autoDismiss: true });
    } catch (error) {
      errorLogger(error, addToast, () => {
        setSubmitLoading(false);
        clearInterval(timerId);
        setResendOtpDisabled(false);
      });
    }
  };

  return (
    <OtpForm onSubmit={handleOtpSubmit}>
      <WelcomeHeading>Enter authentication code</WelcomeHeading>
      <LoginText>
        Enter the 4-digit that we have sent via email <br /> {email}
      </LoginText>
      <OtpInputContainer>
        <OtpInput
          inputStyle={{
            width: '48px',
            height: '48px',
            margin: '0 10px 0 0',
            outline: 'none',
            border: '1px solid #E3E5E6',
            fontSize: theme?.screenSizes['text-base'],
            borderRadius: '8px',
          }}
          focusStyle={{
            border: `1px solid ${theme.colors.primary.main}`,
          }}
          value={otp}
          onChange={handleOtpChange}
          numInputs={4}
          isInputNum
          separator={<span>&nbsp;</span>}
        />
        <TimerText>00:{otpResendTimer === 0 ? '00' : otpResendTimer}</TimerText>
      </OtpInputContainer>
      <CustomButton
        variant={otp.length === 4 ? variants.primary : variants.disabled}
        customStyle={{ width: '200px', height: '50px', borderRadius: '8px' }}
        loading={submitLoading}
        type="submit"
      >
        Continue
      </CustomButton>
      <ResendText onClick={handleOtpResend} disabled={resendOtpDisabled}>
        RESEND OTP
      </ResendText>
    </OtpForm>
  );
};

OtpWidget.propTypes = {
  email: PropTypes.string,
  otp: PropTypes.string,
  setOtp: PropTypes.func,
};

export default OtpWidget;
