import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import RootLayout from './layouts/RootLayout';
import AddGarage from './pages/AddGarage';
import Customers from './pages/Customers';
import Garage from './pages/Garage';
import Garages from './pages/Garages';
import Home from './pages/Home';
import Login from './pages/Login';
import Page404 from './pages/Page404';
import PromoCodes from './pages/PromoCodes';
import Promotions from './pages/PromotionsPage';
import Services from './pages/Services';
import ProtectedRoute from './ProtectedRoute';
import BugReports from './pages/BugReports';
import Editgarage from './pages/Editgarage';
import Editmanager from './pages/Editmanager';
import CreatePromocode from './components/Promocodes/CreatePromocode';
import EditPromoCode from './components/Promocodes/EditPromoCode';
import AdvisorPage from './pages/AdvisorPage';

const PageRouter = () => {
  const user = useSelector((state) => state?.user);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <RootLayout />
            </ProtectedRoute>
          }
        >
          <Route
            path=""
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="garages"
            element={
              <ProtectedRoute>
                <Garages />
              </ProtectedRoute>
            }
          />
          <Route
            path="garage/:garageId"
            element={
              <ProtectedRoute>
                <Garage />
              </ProtectedRoute>
            }
          />
          <Route
            path="garage/editGarage/:garageId"
            element={
              <ProtectedRoute>
                <Editgarage />
              </ProtectedRoute>
            }
          />
          <Route
            path="garage/editManager/:garageId/:userId"
            element={
              <ProtectedRoute>
                <Editmanager />
              </ProtectedRoute>
            }
          />
          <Route
            path="add-garage"
            element={
              <ProtectedRoute>
                <AddGarage />
              </ProtectedRoute>
            }
          />
          <Route
            path="customers"
            element={
              <ProtectedRoute>
                <Customers />
              </ProtectedRoute>
            }
          />
          <Route
            path="services"
            element={
              <ProtectedRoute>
                <Services />
              </ProtectedRoute>
            }
          />
          <Route
            path="advisor"
            element={
              <ProtectedRoute>
                <AdvisorPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="promo-codes"
            element={
              <ProtectedRoute>
                <PromoCodes />
              </ProtectedRoute>
            }
          />
          <Route
            path="promo-codes/createPromoCode"
            element={
              <ProtectedRoute>
                <CreatePromocode />
              </ProtectedRoute>
            }
          />
          <Route
            path={`promo-codes/editPromoCode/:promoCodeId`}
            element={
              <ProtectedRoute>
                <EditPromoCode />
              </ProtectedRoute>
            }
          />
          <Route
            path="promotions"
            element={
              <ProtectedRoute>
                <Promotions />
              </ProtectedRoute>
            }
          />
          <Route
            path="bug-reports"
            element={
              <ProtectedRoute>
                <BugReports />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/" element={<LogoOnlyLayout />}>
          <Route
            path="login"
            element={
              !user.isLoggedIn || !user.userDetails || isEmpty(user.userDetails) ? <Login /> : <Navigate to="/" />
            }
          />
          <Route path="404" element={<Page404 />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PageRouter;
