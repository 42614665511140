import axios from 'axios';
import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';

import store from '../store';
import { signIn } from './redux/userSlice';

const baseURL = process.env.REACT_APP_API_END_POINT;

const state = store.getState();

const apiInstance = axios.create({
  baseURL,
});

apiInstance.interceptors.request.use(
  async (config) => {
    if (state?.user?.isLoggedIn && state?.user?.accessToken && !isEmpty(state?.user?.userDetails)) {
      const decodedHeader = jwtDecode(state.user.accessToken);
      const presentTime = new Date().getTime();
      if (presentTime >= decodedHeader.exp * 1000 + 30000) {
        console.log('Make Api Call To Refresh Token Using Refresh Token');
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/auth/admin/otp/`, {
            refresh_token: state?.user?.refreshToken,
          });
          const newToken = response.data.content;
          config.headers.authorization = `Bearer ${newToken}`;
          store.dispatch(
            signIn({
              accessToken: response?.data?.content || newToken,
            })
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        config.headers.authorization = `Bearer ${state.user.accessToken}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 301) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
