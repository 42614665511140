import { Avatar, Grid } from '@mui/material';
import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useToasts } from 'react-toast-notifications';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import theme from '../../utils/theme';
import CustomInput from '../Adapters/CustomInput';
import CustomButton from '../Adapters/CustomButton';
import variants from '../../utils/variants';
import { warningToastOptions } from '../../utils/constants';


const Container = styled.div`
  padding-top: 10px;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
`;

const Left = styled.div``;

const Right = styled.div`
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h3 {
    color: ${(props) => props.theme.colors.secondary.main};
    font-family: ${(props) => props.theme.fontFamilies.gotham};
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    margin-bottom: 25px;
    font-weight: 600;
  }
`;

const GarageProfile = styled.div`
  margin-bottom: 40px;
  & > h3 {
    color: ${(props) => props.theme.colors.secondary.main};
    font-family: ${(props) => props.theme.fontFamilies.gotham};
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    margin-bottom: 25px;
    font-weight: 600;
  }
`;

const GarageOwnerProfile = styled.div`
  margin-bottom: 40px;
  & > h3 {
    color: ${(props) => props.theme.colors.secondary.main};
    font-family: ${(props) => props.theme.fontFamilies.gotham};
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    margin-bottom: 25px;
    font-weight: 600;
  }
`;

const GarageAddress = styled.div`
  & > h3 {
    color: ${(props) => props.theme.colors.secondary.main};
    font-family: ${(props) => props.theme.fontFamilies.gotham};
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    margin-bottom: 25px;
    font-weight: 600;
  }
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
`;

const Label = styled.label`
  background-color: ${(props) => props.theme.backgroundColors.blueCyan1};
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
`;

const InputContainer = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;

const ErrorText = styled.span`
  color: ${(props) => props.theme.colors.miscellaneous.red};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xs']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-xs']};
`;

const Hr = styled.hr`
  margin: 0 0 25px 0;
  border: none;
  border: ${(props) => `1px solid ${props.theme.colors.miscellaneous.white}`};
`;

const buttonStyle = {
  backgroundColor: theme.colors.primary.main,
  border: 'none',
  boxShadow:
    '0px 109.322px 87.4576px rgba(41, 72, 152, 0.05), 0px 70.8569px 51.2194px rgba(41, 72, 152, 0.037963), 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 21.8644px 14.2119px rgba(41, 72, 152, 0.025), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037)',
  padding: '15px 30px',
  borderRadius: '8px',
  color: theme.colors.miscellaneous.white,
  fontWeight: '600',
  cursor: 'pointer',
  marginTop: '50px',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateGarage':
      return { ...state, garage: { ...state.garage, [action.payload.key]: action.payload.value } };
    case 'updateManager':
      return { ...state, manager: { ...state.manager, [action.payload.key]: action.payload.value } };
    default:
      break;
  }
};

  const acceptedImageTypes = ['image/jpeg', 'image/svg+xml', 'image/png'];

const AddGarage = (props) => {
  const { initialState, handleSubmit, submitLoading } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const { addToast } = useToasts();

  const [errors, setErrors] = useState({
    garageMobileNumber: {
      status: false,
      message: '',
    },
    managerMobileNumber: {
      status: false,
      message: '',
    },
    garageBusinessNumber: {
      status: false,
      message: '',
    },
  });

  const updateGarage = (key, value) => {
    dispatch({ type: 'updateGarage', payload: { key, value } });
  };

  const updateManager = (key, value) => {
    dispatch({ type: 'updateManager', payload: { key, value } });
  };

  const handleGarageNumberInput = (e) => {
    setErrors((state) => ({ ...state, garageMobileNumber: { status: false, error: '' } }));
    updateGarage('contact_number', e);
  };

  const handleGarageBusinessNumberInput = (e) => {
    setErrors((state) => ({ ...state, garageBusinessNumber: { status: false, error: '' } }));
    updateGarage('business_number', e);
  };

  const handleManagerNumberInput = (e) => {
    setErrors((state) => ({ ...state, managerMobileNumber: { status: false, error: '' } }));
    updateManager('phone_number', e);
  };

  const handleGarageNumberInputBlur = () => {
    if (!isValidPhoneNumber(`+${state.garage.contact_number}`)) {
      setErrors((state) => ({ ...state, garageMobileNumber: { status: true, error: 'Invalid Phone Number' } }));
    }
  };

  const handleManagerNumberInputBlur = () => {
    if (!isValidPhoneNumber(`+${state.manager.phone_number}`)) {
      setErrors((state) => ({ ...state, managerMobileNumber: { status: true, error: 'Invalid Phone Number' } }));
    }
  };

  const handleGarageBusinessNumberBlur = () => {
    if (!isValidPhoneNumber(`+${state.garage.business_number}`)) {
      setErrors((state) => ({ ...state, garageBusinessNumber: { status: true, error: 'Invalid Phone Number' } }));
    }
  };

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(state);
        }}
      >
        <Left>
          <GarageProfile>
            <Title>Garage Profile</Title>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>Garage Registered Name *</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <CustomInput
                      placeHolder="Name"
                      type="text"
                      name="Garage Name"
                      required
                      style={{
                        height: '50px',
                        backgroundColor: theme.colors.miscellaneous.white,
                        textTransform: 'capitalize',
                        marginTop: '5px',
                      }}
                      value={state.garage.name}
                      onChange={(e) => {
                        updateGarage('name', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>Contact Number *</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <PhoneInput
                      country={'ca'}
                      enableLongNumbers
                      name="Contact Number"
                      enableSearch
                      value={state.garage.contact_number}
                      onChange={handleGarageNumberInput}
                      containerClass={'phoneInput2Container'}
                      inputClass={'phoneInput2Input'}
                      onBlur={handleGarageNumberInputBlur}
                      style={{ marginTop: '5px' }}
                    />
                    {errors.garageMobileNumber.status && <ErrorText>{errors.garageMobileNumber.error}</ErrorText>}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>Email Address *</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <CustomInput
                      placeHolder="johndeo@gmail.com"
                      type="email"
                      required
                      name="Email"
                      style={{ height: '50px', backgroundColor: theme.colors.miscellaneous.white, marginTop: '5px' }}
                      value={state.garage.email}
                      onChange={(e) => {
                        updateGarage('email', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>Business Number</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <PhoneInput
                      country={'ca'}
                      enableLongNumbers
                      name="Business number"
                      enableSearch
                      value={state?.garage?.business_number}
                      onChange={handleGarageBusinessNumberInput}
                      containerClass={'phoneInput2Container'}
                      inputClass={'phoneInput2Input'}
                      onBlur={handleGarageBusinessNumberBlur}
                      style={{ marginTop: '5px' }}
                    />
                    {errors.garageBusinessNumber.status && <ErrorText>{errors.garageBusinessNumber.error}</ErrorText>}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>Address *</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <CustomInput
                      required
                      placeHolder="100 hill road, St Jhons, NL-L1T5T2"
                      type="text"
                      name="Address"
                      style={{ height: '50px', backgroundColor: theme.colors.miscellaneous.white, marginTop: '5px' }}
                      value={state.garage.address}
                      onChange={(e) => {
                        updateGarage('address', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </GarageProfile>
          <Hr />
          <GarageOwnerProfile>
            <Title>Garage Owner Profile</Title>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>Mobile Number</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <PhoneInput
                      country={'ca'}
                      enableLongNumbers
                      enableSearch
                      value={state.manager.phone_number}
                      onChange={handleManagerNumberInput}
                      containerClass={'phoneInput2Container'}
                      inputClass={'phoneInput2Input'}
                      onBlur={handleManagerNumberInputBlur}
                      style={{ marginTop: '5px' }}
                    />
                    {errors.managerMobileNumber.status && <ErrorText>{errors.managerMobileNumber.error}</ErrorText>}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6} />
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>First Name *</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <CustomInput
                      placeHolder="First Name"
                      type="text"
                      required
                      name="First Name"
                      style={{
                        height: '50px',
                        backgroundColor: theme.colors.miscellaneous.white,
                        textTransform: 'capitalize',
                        marginTop: '5px',
                      }}
                      value={state.manager.first_name}
                      onChange={(e) => {
                        updateManager('first_name', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>Last Name *</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <CustomInput
                      placeHolder="Last Name"
                      type="text"
                      name="Last name"
                      required
                      style={{
                        height: '50px',
                        backgroundColor: theme.colors.miscellaneous.white,
                        textTransform: 'capitalize',
                        marginTop: '5px',
                      }}
                      value={state.manager.last_name}
                      onChange={(e) => {
                        updateManager('last_name', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <Label>Email Address *</Label>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <CustomInput
                      placeHolder="johndeo@gmail.com"
                      type="email"
                      name="Email"
                      required
                      style={{ height: '50px', backgroundColor: theme.colors.miscellaneous.white, marginTop: '5px' }}
                      value={state.manager.email}
                      onChange={(e) => {
                        updateManager('email', e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={6} xl={16} />
            </Grid>
          </GarageOwnerProfile>
          <CustomButton variant={variants.primary} customStyle={buttonStyle} type="submit" loading={submitLoading}>
            SUBMIT
          </CustomButton>
        </Left>
        <Right>
          <Title>Garage Logo</Title>
          <Avatar
            sx={{ width: 150, height: 150 }}
            src={state?.garage?.logo ? URL.createObjectURL(state.garage.logo) : ''}
          />
          <Label htmlFor="garageLogo">
            <CameraAltIcon sx={{ mt: 1, cursor: 'pointer', color: theme.colors.primary.main }} />
            <input
              type="file"
              accept="image/png, image/jpeg, image/svg"
              id="garageLogo"
              style={{ display: 'none' }}
              onChange={(e) => {
                const image = e.target.files[0];
                if (acceptedImageTypes.includes(image.type)) {
                  return updateGarage('logo', e.target.files[0]);
                }
                return addToast('Invalid file type', warningToastOptions);
              }}
            />
          </Label>
        </Right>
      </Form>
    </Container>
  );
};

AddGarage.propTypes = {
  initialState: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitLoading: PropTypes.bool,
};

AddGarage.defaultProps = {
  initialState: {
    garage: { email: '', contact_number: '', name: '', logo: '', tax_percentage: '', business_number: '' },
    manager: {
      email: '',
      phone_number: '',
      last_name: '',
      first_name: '',
      tax: '',
    },
    address: {
      city: '',
      lat: '',
      lng: '',
      street: '',
      postal_code: '',
    },
  },
};

export default AddGarage;
