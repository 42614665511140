import { Avatar, Box, Grid, Switch } from '@mui/material';
import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-phone-input-2/lib/bootstrap.css';
import { useSelector } from 'react-redux';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Zoom from 'react-medium-image-zoom';
import { useToasts } from 'react-toast-notifications';
import { isValidPhoneNumber } from 'react-phone-number-input';
import theme from '../../utils/theme';
import CustomInput from '../Adapters/CustomInput';
import CustomButton from '../Adapters/CustomButton';
import { convertHashMapToArray, errorLogger, isUrl, uploadMediaToFireBase } from '../../utils/helpers/component';
import variants from '../../utils/variants';
import { BUTTON } from '../../utils/localization';
import { errorToastOptions, successToastOptions, warningToastOptions } from '../../utils/constants';
import { capitalize } from '../../utils/helpers/string';
import apiInstance from '../../utils/apiInstance';
import GoogleSeachPlaces from '../GarageSearchPlaces';


const Container = styled.form``;

const Heading = styled.h3`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.gotham};
  font-size: ${(props) => props.theme.fontSizes['text-xl']};
  font-weight: 400;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const GarageProfile = styled.div``;

const GarageAddress = styled.div`
  margin-top: 25px;
  & > h3 {
    color: ${(props) => props.theme.colors.secondary.main};
    font-family: ${(props) => props.theme.fontFamilies.gotham};
    font-size: ${(props) => props.theme.fontSizes['text-xl']};
    margin-bottom: 25px;
    font-weight: 600;
  }
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-sm']};
  font-weight: 400;
`;

const InputContainer = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;

const ErrorText = styled.span`
  color: ${(props) => props.theme.colors.miscellaneous.red};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-xs']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-xs']};
`;

const SwitchContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 500px;
  gap: 16px;
`;
const SwitchFormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
`;

const submitButtonStyle = {
  border: 'none',
  borderRadius: '8px',
  padding: '10px 30px',
  boxShadow: 'none',
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: 600,
};

const cancelButtonStyle = {
  border: 'none',
  borderRadius: '8px',
  padding: '10px 30px',
  boxShadow: 'none',
  fontSize: theme.fontSizes['text-sm'],
  fontWeight: 600,
  color: theme.colors.secondary.main,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateGarage':
      return { ...state, garage: { ...state.garage, [action.payload.key]: action.payload.value } };
    case 'updateAddress':
      return { ...state, address: { ...state.address, [action.payload.key]: action.payload.value } };
    case 'updateFeatureFlag':
      return {
        ...state,
        garage: {
          ...state.garage,
          feature_flags: { ...state.garage.feature_flags, [action.payload.key]: action.payload.value },
        },
      };
    default:
      break;
  }
};

const acceptedImageTypes = ['image/jpeg', 'image/svg+xml', 'image/png'];

const EditGarageProfile = () => {
  const params = useParams();
  const garages = useSelector((state) => state?.garages);
  const { addToast } = useToasts();

  const { garageId } = params;

  const allGarages = convertHashMapToArray(garages?.value);
  const garage = allGarages.filter((garage) => garage.id === parseInt(garageId, 10))[0];
  const initialState = {
    garage: {
      id: garage?.id,
      email: garage?.email,
      contact_number: garage?.contact_number,
      business_number: garage?.business_number,
      name: garage?.name,
      logo: garage?.logo,
      address: garage?.address,
      feature_flags: {
        newRouter: garage?.feature_flags?.newRouter,
      },
    },
  };
  const [state, localDispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const [isGarageDetailsUpdated, setIsGarageDetailsUpdated] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [errors, setErrors] = useState({
    garageMobileNumber: {
      status: false,
      message: '',
    },
    garageBusinessNumber: {
      status: false,
      message: '',
    },
  });

  const updateGarage = (key, value) => {
    setIsGarageDetailsUpdated(true);
    localDispatch({ type: 'updateGarage', payload: { key, value } });
  };

  const handleImageUpload = (e) => {
    const image = e.target.files[0];
    if (acceptedImageTypes.includes(image.type)) {
      setIsImageUpdated(true);
      setIsGarageDetailsUpdated(true);
      return updateGarage('logo', e.target.files[0]);
    }
    return addToast('Invalid file type', warningToastOptions);
  };

  const handleGarageNumberInput = (e) => {
    setErrors((state) => ({ ...state, garageMobileNumber: { status: false, error: '' } }));
    updateGarage('contact_number', `+${e}`);
  };

  const handleGarageBusinessNumberInput = (e) => {
    setErrors((state) => ({ ...state, garageBusinessNumber: { status: false, error: '' } }));
    updateGarage('business_number', e);
  };

  const handleGarageNumberInputBlur = () => {
    if (!isValidPhoneNumber(state.garage.contact_number)) {
      setIsGarageDetailsUpdated(true);
      setErrors((state) => ({ ...state, garageMobileNumber: { status: true, error: 'Invalid Phone Number' } }));
    }
  };

  const handleGarageBusinessNumberBlur = () => {
    if (!isValidPhoneNumber(`+${state.garage.business_number}`)) {
      setErrors((state) => ({ ...state, garageBusinessNumber: { status: true, error: 'Invalid Phone Number' } }));
    }
  };

  const handleCloseEditGarage = () => {
    navigate(`/garage/${garage.id}`);
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setIsGarageDetailsUpdated(true);
    localDispatch({ type: 'updateFeatureFlag', payload: { key: name, value: checked } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isGarageDetailsUpdated) {
      if (isValidPhoneNumber(state.garage.contact_number)) {
        setSubmitButtonLoading(true);
        const logo = isImageUpdated ? await uploadMediaToFireBase(state?.garage?.logo) : state?.garage?.logo;
        const garageDetails = {
          logo,
          id:  parseInt(`${state?.garage?.id}`, 10),
          email: `${state?.garage?.email}`,
          businessNumber: `${state?.garage?.business_number}`,
          contactNumber: `${state?.garage?.contact_number}`,
          address: `${state?.garage?.address}`,
          name: capitalize(state?.garage?.name),
        };
        console.log(garageDetails);
        const body = {
          ...garageDetails,
        };
        try {
          await apiInstance.patch(`organization/admin`, body);
          window.location.href = `/garage/${garage.id}`;
          setIsGarageDetailsUpdated(false);
          addToast('Updated garage details successfully', successToastOptions);
        } catch (error) {
          errorLogger(error, addToast);
        } finally {
          setSubmitButtonLoading(false);
        }
      } else {
        return addToast('Invalid contact number', errorToastOptions);
      }
    } else {
      addToast('No changes to update', warningToastOptions);
    }
  };

  return (
    <Container onSubmit={handleSubmit}>
      <GarageProfile>
        <i
          className="fa-sharp fa-solid fa-arrow-left fa-lg"
          style={{
            color: theme.colors.secondary.main,
            fontSize: '1.3rem',
            cursor: 'pointer',
            lineHeight: '3rem',
          }}
          onClick={handleCloseEditGarage}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Box sx={{ display: 'grid', placeItems: 'center', width: 'fit-content', mb: 2 }}>
              <Zoom>
                <Avatar
                  sx={{ width: 150, height: 150 }}
                  src={isUrl(state?.garage?.logo) ? state?.garage?.logo : URL.createObjectURL(state?.garage?.logo)}
                />
              </Zoom>
              <Label htmlFor="updateGarageProfileImage">
                <CameraAltIcon sx={{ mt: 1, cursor: 'pointer', color: theme.colors.primary.main }} />
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/svg"
                  id="updateGarageProfileImage"
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                />
              </Label>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Label>Garage Registered Name *</Label>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <CustomInput
                  placeHolder="Name"
                  type="text"
                  required
                  style={{
                    height: '50px',
                    backgroundColor: theme.colors.miscellaneous.white,
                    textTransform: 'capitalize',
                    border: `1px solid ${theme.colors.miscellaneous.greyLightest}`,
                    marginTop: '0',
                  }}
                  value={state.garage.name}
                  onChange={(e) => {
                    updateGarage('name', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Label>Contact Number</Label>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <PhoneInput
                  country={'ca'}
                  enableLongNumbers
                  enableSearch
                  containerClass={'phoneInput2ContainerEditGarageProfile'}
                  value={state.garage.contact_number}
                  onChange={handleGarageNumberInput}
                  onBlur={handleGarageNumberInputBlur}
                  inputClass={'phoneInput2InputEditGarageProfile'}
                />
                {errors.garageMobileNumber.status && <ErrorText>{errors.garageMobileNumber.error}</ErrorText>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Label>Email Address *</Label>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <CustomInput
                  placeHolder="johndeo@gmail.com"
                  type="email"
                  required
                  style={{
                    height: '50px',
                    backgroundColor: theme.colors.miscellaneous.white,
                    border: `1px solid ${theme.colors.miscellaneous.greyLightest}`,
                    marginTop: '0',
                  }}
                  value={state.garage.email}
                  onChange={(e) => {
                    updateGarage('email', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Label>Business Number</Label>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <PhoneInput
                  country={'ca'}
                  enableLongNumbers
                  enableSearch
                  containerClass={'phoneInput2ContainerEditGarageProfile'}
                  value={
                    state?.garage?.business_number?.charAt(0) === '+'
                      ? state.garage.business_number.substring(1)
                      : state.garage.business_number
                  }
                  onChange={handleGarageBusinessNumberInput}
                  onBlur={handleGarageBusinessNumberBlur}
                  inputClass={'phoneInput2InputEditGarageProfile'}
                />
                {errors.garageBusinessNumber.status && <ErrorText>{errors.garageBusinessNumber.error}</ErrorText>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GarageProfile>
      <GarageAddress>
        <InputContainer>
          <Label>Add Location</Label>
          <CustomInput
            placeHolder=""
            type="address"
            required
            style={{
              height: '50px',
              backgroundColor: theme.colors.miscellaneous.white,
              border: `1px solid ${theme.colors.miscellaneous.greyLightest}`,
              marginTop: '0',
            }}
            value={state.garage.address}
            onChange={(e) => {
              updateGarage('address', e.target.value);
            }}
          />
          {/* <GoogleSeachPlaces
            callback={handleUpdateAddress}
            containerStyle={{ marginTop: '10px' }}
            inputStyle={{
              backgroundColor: '#fff',
              border: `1px solid ${theme.colors.miscellaneous.greyLightest}`,
            }}
          /> */}
        </InputContainer>
        <Heading style={{ margin: '30px 0' }}>Feature Flagging</Heading>
        <SwitchContainer>
          <SwitchFormGroup>
            <Label htmlFor="newRouter">Router</Label>
            <Switch checked={state.garage.feature_flags.newRouter} onChange={handleSwitchChange} name="newRouter" />
          </SwitchFormGroup>
        </SwitchContainer>
        <Grid container spacing={1} sx={{ padding: '40px 0 40px 0' }}>
          <Grid item>
            <CustomButton
              variant={variants.primary}
              customStyle={submitButtonStyle}
              loading={submitButtonLoading}
              loaderStyle={{ fontSize: theme.fontSizes['text-sm'] }}
              title={isGarageDetailsUpdated ? 'Click to update' : 'No changes to update'}
            >
              Update
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              variant={variants.secondary}
              customStyle={cancelButtonStyle}
              type={BUTTON}
              onClick={handleCloseEditGarage}
            >
              Cancel
            </CustomButton>
          </Grid>
        </Grid>
      </GarageAddress>
    </Container>
  );
};

EditGarageProfile.propTypes = {};

EditGarageProfile.defaultProps = {};

export default EditGarageProfile;
