import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');
const { statsInitialState } = require('./states');

const initialState = {isLoading: false, stats: []};

export const fetchDashboardStatsAsync = createAsyncThunk('dashboard/fetchAsync', async (input, thunkApi) => {

  const { successCallback, errorCallback } = input;
  const { getState, rejectWithValue } = thunkApi;

  const { user } = getState();
  const config = {
    headers: {
      authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    const stats = await axios.get(`${process.env.REACT_APP_API_END_POINT}/advisor/stats/admin?&from=0&to=100000`, config);
    successCallback();
    return stats.data.content;
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardStatsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDashboardStatsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stats = action.payload;
    });
    builder.addCase(fetchDashboardStatsAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const selectDashboardStats = (state) => state.dashboard.stats;
export const selectIsDashboardLoading = (state) => state.dashboard.isLoading;

export default dashboardSlice.reducer;
