import React, { useState } from "react";
import styled from "styled-components";
import { TextField, Button } from "@mui/material";

const DuplicatePathwayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 400px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const FormItem = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const DuplicatePathway = () => {
  const [formValues, setFormValues] = useState({
    pathwayId: "",
    newPathwayName: "",
    transferNumber: "",
    managerName: "",
    other: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form Submitted:", formValues);
  };

  return (
    <DuplicatePathwayContainer>
      <form onSubmit={handleSubmit}>
        <FormItem>
          <TextField
            fullWidth
            label="PathwayId"
            name="pathwayId"
            value={formValues.pathwayId}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            label="New Pathway Name"
            name="newPathwayName"
            value={formValues.newPathwayName}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            label="Transfer Number"
            name="transferNumber"
            value={formValues.transferNumber}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            label="Manager Name(s)"
            name="managerName"
            value={formValues.managerName}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem>
          <TextField
            fullWidth
            label="Other"
            name="other"
            value={formValues.other}
            onChange={handleChange}
          />
        </FormItem>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </DuplicatePathwayContainer>
  );
};

export default DuplicatePathway;
