import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import SummaryWidget from './SummaryWidget';
import { CURRENCY, NUMBER } from '../../utils/localization';
import { selectDashboardStats } from '../../utils/redux/dashboardSlice';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
`;

const SummaryContent = (props) => {

  const stats = useSelector(selectDashboardStats);
  console.log(stats)
  const totalCalls = stats.reduce((sum, stat) => sum + stat.count, 0);
  const totalAiHandledCalls = stats.reduce((sum, stat) => sum + stat.handledByAi, 0);
  const data = [
    { title: 'TOTAL GARAGES', type: NUMBER, value: stats.length },
    { title: 'TOTAL CALLS', type: NUMBER, value: totalCalls },
    { title: 'TOTAL AI HANDLED CALLS', type: NUMBER, value: totalAiHandledCalls },
  ];
  return (
    <Container>
      {data.map((item, index) => (
        <SummaryWidget key={index} item={item} />
      ))}
    </Container>
  );
};

SummaryContent.propTypes = {
  stats: PropTypes.object,
};

export default SummaryContent;
