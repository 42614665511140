import React, { useState } from "react";
import styled from "styled-components";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import Page from "../components/Page";
import DuplicatePathway from "../components/Advisor/DuplicatePathway";
import CreatePathway from "../components/Advisor/CreatePathway";
import ModifyPathway from "../components/Advisor/ModifyPathway";

const AdvisorContentContainer = styled.div`
  margin: auto;
  padding: 20px;
`;

const CustomContent = styled.div`
  margin-top: 20px;
`;

const AdvisorPage = () => {
  const [selectedOption, setSelectedOption] = useState("duplicate");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderContent = () => {
    switch (selectedOption) {
      case "duplicate":
        return <DuplicatePathway />;
      case "create":
        return <CreatePathway />;
      case "modify":
        return <ModifyPathway />;
      default:
        return <CustomContent>Select an option to see the content.</CustomContent>;
    }
  };

  return (
    <Page>
      <AdvisorContentContainer>
        <FormControl fullWidth>
          <InputLabel id="select-label">Choose an Option</InputLabel>
          <Select
            labelId="select-label"
            value={selectedOption}
            onChange={handleChange}
            label="Choose an Option"
          >
            <MenuItem value="duplicate">Duplicate for Another Location</MenuItem>
            <MenuItem value="create">Create Pathway from Requirements</MenuItem>
            <MenuItem value="modify">Modify Exisiting from Prompt</MenuItem>
          </Select>
        </FormControl>
        {renderContent()}
      </AdvisorContentContainer>
    </Page>
  );
};

export default AdvisorPage;
