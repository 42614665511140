import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Page from '../components/Page';
import variants from '../utils/variants';
import SummaryContent from '../components/Dashboard/SummaryContent';
import { fetchDashboardStatsAsync, selectDashboardStats } from '../utils/redux/dashboardSlice';



const Home = () => {
  const { user } = useSelector((state) => ({
    user: state?.user,
  }));

  const dashboardStats = useSelector(selectDashboardStats);
  const dispatch = useDispatch();

  console.log(dashboardStats)

  const getGreetings = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good Morning';
    }
    if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon';
    }
    return 'Good Evening';
  };

  const greetMessage = getGreetings();


  return (
    <Page
      loading={dashboardStats?.isLoading}
      header={`${greetMessage}, ${user?.userDetails?.name}`}
      variant={variants.general}
      title="Home"
    >
      <SummaryContent />
    </Page>
  );
};

export default Home;
