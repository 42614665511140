import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';

import variants from '../../utils/variants';
import CustomButton from '../Adapters/CustomButton';
import apiInstance from '../../utils/apiInstance';
import { OTP } from '../../utils/localization';
import { errorLogger } from '../../utils/helpers/component';

const EmailForm = styled.form``;

const LoginText = styled.div`
  color: ${(props) => props.theme.colors.secondary.main};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-size: ${(props) => props.theme.fontSizes['text-3xl']};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  margin-top: 5px;
`;

const TextField = styled.input`
  display: block;
  font-size: ${(props) => props.theme.fontSizes['text-base']};
  font-family: ${(props) => props.theme.fontFamilies.lato};
  font-weight: 400;
  line-height: ${(props) => props.theme.lineHeights['text-base']};
  height: 48px;
  width: 350px;
  border: none;
  border: 1px solid #e3e5e6;
  border-radius: 8px;
  padding: 10px;
  margin: 30px 0 50px 0;
  &:hover {
    border: ${(props) => `2px solid ${props.theme.colors.primary.main}`};
  }
  &:focus {
    outline: none;
    border: ${(props) => `2px solid ${props.theme.colors.primary.main}`};
  }
`;

const EmailWidget = (props) => {
  const { email, setEmail, setAuthWidget } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const { addToast } = useToasts();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      await apiInstance.post('/auth/admin/otp/create', { email });
      setSubmitLoading(false);
      setAuthWidget(OTP);
      addToast(`OTP Sent Successfully To ${email}`, { appearance: 'success', autoDismiss: true });
    } catch (error) {
      errorLogger(error, addToast, () => {
        setSubmitLoading(false);
      });
    }
  };

  return (
    <EmailForm onSubmit={handleEmailSubmit}>
      <LoginText>Log in to Admin Dashboard</LoginText>
      <TextField
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        placeholder="Email ID"
        type="email"
        required
      />
      <CustomButton
        variant={variants.primary}
        customStyle={{ width: '200px', height: '50px', borderRadius: '8px' }}
        loading={submitLoading}
        type="submit"
      >
        Login
      </CustomButton>
    </EmailForm>
  );
};

EmailWidget.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
  setAuthWidget: PropTypes.func,
};

export default EmailWidget;
