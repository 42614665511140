import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';

import PageRouter from './PageRouter';
import { useStore } from './utils/hooks/useStore';
import { updateScreenWidth } from './utils/redux/helpersSlice';
import { checkAuthStateFromServer } from './utils/functions';
import { signOut } from './utils/redux/userSlice';
import { removeUserPersistace } from './utils/persistance';
import LoadingComponent from './components/Adapters/LoadingComponent';

const LoadingWindow = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
`;

const loaderStyle = {
  fontSize: '3rem',
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  useStore();
  useEffect(() => {
    dispatch(updateScreenWidth());
    checkAuthStateFromServer({
      setLoading: (bool) => {
        setLoading(bool);
      },
      signOutCallback: () => {
        removeUserPersistace();
        dispatch(signOut());
        setLoading(false);
      },
      dispatch,
      addToast,
    });
  }, [dispatch]);

  return loading ? (
    <LoadingWindow>
      <LoadingComponent customStyle={loaderStyle} />
    </LoadingWindow>
  ) : (
    <PageRouter />
  );
};

export default App;
