import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { garagesInitialState } from './states';
import { convertArrayToHashMap } from '../helpers/component';

const initialState = garagesInitialState;

export const fetchGaragesAsync = createAsyncThunk('garages/fetchAsync', async (input, thunkApi) => {
  const { successCallback, errorCallback } = input;
  const { getState, rejectWithValue } = thunkApi;
  const { user } = getState();
  const config = {
    headers: {
      authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    const garages = await axios.get(`${process.env.REACT_APP_API_END_POINT}/organization/admin`, config);
    successCallback();
    return convertArrayToHashMap(garages.data.content, 'id');
  } catch (error) {
    errorCallback(error);
    return rejectWithValue(error.response.data);
  }
});

const garagesSlice = createSlice({
  name: 'garages',
  initialState,
  reducers: {
    setGarages: (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGaragesAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGaragesAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload;
    });
    builder.addCase(fetchGaragesAsync.rejected, (state) => {
      state.isLoading = true;
    });
  },
});

export const { setGarages } = garagesSlice.actions;

export default garagesSlice.reducer;
