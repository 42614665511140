import { useEffect } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import NavSidebarOpenBackground from '../components/Navbar/NavSidebarOpenBackground';
import NavSidebar from '../components/Navbar';
import { fetchGaragesAsync } from '../utils/redux/garagesSlice';
import { errorLogger } from '../utils/helpers/component';
import CustomModal from '../components/Adapters/CustomModal';
import AddTypeModalContent from '../components/AddTypeModalContent';
import AddCategoryModalContent from '../components/AddCategoryModalContent';
import {
  closeAddCategoryModal,
  closeAddTypeModal,
  closeCreateServiceModal,
  closeUserProfileModal,
} from '../utils/redux/modalSlice';
import { fetchTypesAsync } from '../utils/redux/typesSlice';
import { fetchCategoriesAsync } from '../utils/redux/categoriesSlice';
import { fetchServicesAsyncThunk } from '../utils/redux/servicesSlice';
import UserProfileDrawerContent from '../components/UserProfileDrawerContent';
import CustomDrawer from '../components/Adapters/CustomDrawer';
import CreateServiceModalContent from '../components/CreateServiceModalContent';
import { fetchBookingsAsync } from '../utils/redux/bookingsSlice';
import { fetchStatsAsync } from '../utils/redux/statsSlice';
import { fetchCustomersAsync } from '../utils/redux/customersSlice';
import { fetchDashboardStatsAsync } from '../utils/redux/dashboardSlice';

const Container = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
`;

const Children = styled.div`
  margin: 100px 0 0 105px;
  padding: 0px 20px 0 40px;
  @media screen and (max-width: 1200px) {
    padding: 0px 15px 0 15px;
  }
`;

const RootLayout = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const modals = useSelector((state) => state?.modals);

  useEffect(() => {
    dispatch(
      fetchGaragesAsync({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
    
    dispatch(fetchDashboardStatsAsync({
      successCallback: () => {},
      errorCallback: (error) => {
        errorLogger(error, addToast);
      },
      value: { config: {} },
    }));

    dispatch(
      fetchTypesAsync({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
    dispatch(
      fetchCategoriesAsync({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
    dispatch(
      fetchServicesAsyncThunk({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
    dispatch(
      fetchCustomersAsync({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
    dispatch(
      fetchBookingsAsync({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
    dispatch(
      fetchStatsAsync({
        successCallback: () => {},
        errorCallback: (error) => {
          errorLogger(error, addToast);
        },
        value: { config: {} },
      })
    );
  }, []);

  return (
    <Container>
      <NavSidebar />
      <Children>
        <Outlet />
      </Children>
      <NavSidebarOpenBackground />
      <CustomModal
        open={modals.addTypeModal}
        handleClose={() => {
          dispatch(closeAddTypeModal());
        }}
        heading="Add New Service Type"
      >
        <AddTypeModalContent />
      </CustomModal>
      <CustomModal
        open={modals.addCategoryModal}
        handleClose={() => {
          dispatch(closeAddCategoryModal());
        }}
        heading="Add New Service Category"
      >
        <AddCategoryModalContent />
      </CustomModal>
      <CustomModal
        open={modals.createServiceModal}
        handleClose={() => {
          dispatch(closeCreateServiceModal());
        }}
        heading="Add Service"
      >
        <CreateServiceModalContent />
      </CustomModal>
      <CustomDrawer
        open={modals.userProfileModal}
        handleClose={() => {
          dispatch(closeUserProfileModal());
        }}
        anchor="right"
        heading="Admin Profile"
        containerStyle={{
          width: '400px',
          position: 'relative',
        }}
      >
        <UserProfileDrawerContent />
      </CustomDrawer>
    </Container>
  );
};

export default RootLayout;
