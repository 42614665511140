import { configureStore } from '@reduxjs/toolkit';
import userReducer from './utils/redux/userSlice';
import bookingReducer from './utils/redux/bookingsSlice';
import helperReducer from './utils/redux/helpersSlice';
import modalReducer from './utils/redux/modalSlice';
import servicesReducer from './utils/redux/servicesSlice';
import garagesReducer from './utils/redux/garagesSlice';
import typesReducer from './utils/redux/typesSlice';
import categoriesReducer from './utils/redux/categoriesSlice';
import { loadState } from './utils/helpers/redux';
import promocodesSlice from './utils/redux/promocodesSlice';
import promotionsSlice from './utils/redux/promotionsSlice';
import customersSlice from './utils/redux/customersSlice';
import statsSlice from './utils/redux/statsSlice';
import dashboardSlice from './utils/redux/dashboardSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    bookings: bookingReducer,
    helpers: helperReducer,
    modals: modalReducer,
    services: servicesReducer,
    garages: garagesReducer,
    serviceTypes: typesReducer,
    serviceCategories: categoriesReducer,
    promoCodes: promocodesSlice,
    promotions: promotionsSlice,
    customers: customersSlice,
    stats: statsSlice,
    dashboard: dashboardSlice
  },
  preloadedState: loadState(),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
