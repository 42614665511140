import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-input-2/lib/style.css';
import Page from '../components/Page';
import variants from '../utils/variants';
import { errorLogger, uploadMediaToFireBase } from '../utils/helpers/component';
import apiInstance from '../utils/apiInstance';
import { garagesRoute } from '../utils/redirects';
import AddGarageComponent from '../components/AddGarage';
import { capitalize } from '../utils/helpers/string';

const AddGarage = () => {
  const { addToast } = useToasts();

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async (state) => {
    const isGarageNumberPossible = isValidPhoneNumber(`+${state?.garage?.contact_number}`);
    const isManagerNumberPossible = isValidPhoneNumber(`+${state?.manager?.phone_number}`);
    if (state?.garage?.logo && isGarageNumberPossible && isManagerNumberPossible) {
      try {
        setSubmitLoading(true);
        const logoUrl = await uploadMediaToFireBase(state?.garage?.logo);
        const user = {
          email: `${state?.manager?.email}`,
          phoneNumber: `+${state?.manager?.phone_number}`,
          firstName: capitalize(state?.manager?.first_name),
          lastName: capitalize(state?.manager?.last_name),
        };
        const garage = {
          logo: logoUrl,
          email: `${state?.garage?.email}`,
          contactNumber: `+${state?.garage?.contact_number}`,
          businessNumber: `+${state?.garage?.business_number}`,
          address: state?.garage?.address,
          name: capitalize(state?.garage?.name),
        };
        const body = {
          user: { ...user },
          ...garage,
        };
        console.log(body)
        await apiInstance.post('/organization', body);
        window.location.href = garagesRoute;
      } catch (error) {
        errorLogger(error, addToast, () => {});
      } finally {
        setSubmitLoading(false);
      }
    } else if (!state.garage.logo) {
      errorLogger({ message: 'Logo is required' }, addToast, () => {});
    } else if (!isGarageNumberPossible) {
      errorLogger({ message: 'Garage number invalid' }, addToast, () => {});
    } else {
      errorLogger({ message: 'Manager number invalid' }, addToast, () => {});
    }
  };

  return (
    <Page variant={variants.addGarage} header="Garages" title="Garages">
      <AddGarageComponent handleSubmit={handleSubmit} submitLoading={submitLoading} />
    </Page>
  );
};

export default AddGarage;
